import { sx } from '@lucifer/sx';
import { media } from '~/utils/styles';

export const HeadlineSubS1 = sx('span')(
  {
    wordBreak: 'break-word',
    fontSize: 16,
    lineHeight: 1.4,
    letterSpacing: 0,
    fontWeight: 300,
    fontStyle: 'italic',

    [media('tablet')]: {
      fontSize: 22,
    },
  },
  props => ({
    fontFamily: props.theme.fontPrimary,
    color: props.theme.sublineS1Color,
  }),
);
