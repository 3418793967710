import { sx } from '@lucifer/sx';

import LinkComponent from '~/components/Link';
import { Box } from 'theme-ui';
import Image from '~/components/Image';

export const ProjectImage = sx(Image)({
  transform: 'translateX(20px)',
  transition: 'all 300ms ease-out',
  maxWidth: 319,
  height: 'auto',
});

export const HeadlineMeta = sx(Box)({
  display: 'block',
  fontSize: 10,
  fontWeight: 900,
  color: '#9F9F9F',
  letterSpacing: '0.02em',
  lineHeight: '27px',
  textTransform: 'uppercase',
  fontStyle: 'normal',
  margin: '45px 0px 10px 23px',
  transition: 'all 300ms ease-out',
});

export const ProjectTitle = sx(Box)({
  fontSize: 17,
  fontWeight: 600,
  letterSpacing: '0.02em',
  lineHeight: '27px',
  textTransform: 'uppercase',
  transition: 'all 300ms ease-out',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'flex-end',

  svg: {
    marginLeft: 35,
    transition: 'all 500ms ease-out',
    marginBottom: '8px',
  },
});

export const TeaserText = sx(Box)({
  paddingRight: 50,
});

export const Shorten = sx('span')({
  display: 'inline-block',
  maxWidth: 150,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'right',
});

export const TeaserProject = sx(LinkComponent)({
  paddingTop: 37,
  paddingBottom: 53,
  paddingLeft: 71,
  display: 'block',
  background: '#ffffff',
  overflow: 'hidden',
  transition: 'all 300ms ease-out',
  color: 'black',

  '&:hover': {
    color: 'inherit',

    [ProjectTitle]: {
      transform: 'translateX(5px)',
    },

    [HeadlineMeta]: {
      transform: 'translateX(-5px)',
    },

    [ProjectImage]: {
      transform: 'translateX(0px)',
    },

    svg: {
      transform: 'translateX(10px)',
    },
  },
});
