import React from 'react';

import Icon from '~/components/Icon';

import {
  TeaserProject,
  ProjectTitle,
  TeaserText,
  Shorten,
  ProjectImage,
  HeadlineMeta,
} from './TeaserProject.sx';

export default ({ project }) => {
  return (
    <TeaserProject to={project?.page?.slug?.current}>
      <ProjectImage image={project?._rawProjectInfo?.teaserImage} />
      <TeaserText>
        <ProjectTitle>
          <Shorten>
            <HeadlineMeta>next client</HeadlineMeta>
            {project?._rawProjectInfo?.projectTitle}
          </Shorten>
          <Icon type="arrowRightLong" />
        </ProjectTitle>
      </TeaserText>
    </TeaserProject>
  );
};
