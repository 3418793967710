import React from 'react';
import { Flipped } from 'react-flip-toolkit';

import { Flex } from 'theme-ui';

import Button from '~/components/Button';

import {
  Hero,
  HeroInfoWrap,
  HeroInfoWrapImage,
  HeroInfoWrapMeta,
  HeroInfoFlex,
  HeroHeader,
  HeroIntro,
  HeroIntroWrap,
  HeroHeaderImage,
  HeroDetailImage,
  TopTitle,
  ProjectTitle,
  MetaTable,
  MetaTableItem,
  MetaTableTitle,
  MetaTableImage,
  MetaTableImageWrap,
  MetaTableText,
} from './SectionProjectHero.sx';

export default ({ projectInfo, section }) => {
  const {
    projectTitle,
    heroAsset,
    image,
    client,
    topline,
    description,
    keyroles,
    linkText,
    url,
  } = projectInfo;

  return (
    <Hero>
      <HeroHeader>
        <Flipped flipId="projectHero">
          <HeroHeaderImage image={image} />
        </Flipped>
      </HeroHeader>

      <HeroIntro>
        <HeroIntroWrap>
          <Flex sx={{ flexDirection: 'column' }}>
            <TopTitle element="HeadlineSubS1">{topline}</TopTitle>
            <ProjectTitle element="h1">{projectTitle}</ProjectTitle>
          </Flex>
          <Flex>
            <Button
              to={url.href}
              blank={true}
              variant="circle"
              metaTitle="See it live"
              >
              {linkText || url.href}
            </Button>
          </Flex>
        </HeroIntroWrap>
      </HeroIntro>

      <HeroInfoWrap>
        <HeroInfoFlex>
          <HeroInfoWrapMeta>
            <MetaTable>
              <MetaTableItem>
                <MetaTableTitle>Client</MetaTableTitle>
                <MetaTableImageWrap>
                  <MetaTableImage image={client} />
                </MetaTableImageWrap>
              </MetaTableItem>
              <MetaTableItem>
                <MetaTableTitle>Work</MetaTableTitle>
                <MetaTableText content={keyroles} />
              </MetaTableItem>
              <MetaTableItem>
                <MetaTableTitle>Intro</MetaTableTitle>
                <MetaTableText content={description} />
              </MetaTableItem>
            </MetaTable>
          </HeroInfoWrapMeta>
          <HeroInfoWrapImage>
            <HeroDetailImage image={heroAsset} />
          </HeroInfoWrapImage>
        </HeroInfoFlex>
      </HeroInfoWrap>
    </Hero>
  );
};
