import React from 'react';

import { Flex, Box } from 'theme-ui';
import Section from '~/components/Section';
import Icon from '~/components/Icon';
import TeaserProject from '~/components/TeaserProject';

import { Link, LinkText } from './SectionProjectFooter.sx';

export default ({ next, background }) => {
  return (
    <Section
      section={{
        bgImage: background,
        paddingBottom: 'none',
        paddingTop: 'none',
        heightIntrinsic: true,
      }}
    >
      {/* TODO: background from project settings? */}
      <Flex
        style={{
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Box>
          {/* TODO: link not hardcoded */}
          <Link to="/projects">
            <Icon type="arrowLeftLong" />
            <LinkText>Overview</LinkText>
          </Link>
        </Box>
        <Flex style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
          {/* {previous && <TeaserProject project={previous} />} */}
          {next && <TeaserProject project={next} />}
        </Flex>
      </Flex>
    </Section>
  );
};
