import React from 'react';

import { HeadlineSubS1 } from './HeadlineSub.sx';

export default ({ element, children, className }) => {
  let HeadlineSub;

  switch (element) {
    case 'HeadlineSubS1':
    default:
      HeadlineSub = HeadlineSubS1;
      break;
  }

  return <HeadlineSub className={className}>{children}</HeadlineSub>;
};
