import React from 'react';
import Layout from '~/components/Layout';
import Sections from '~/components/Sections';
import SectionProjectHero from '~/components/SectionProjectHero';
import SectionProjectFooter from '~/components/SectionProjectFooter';

const getVisibleProjects = (projectEdges) => {
  return projectEdges.filter((project) => project.node.page.hidden !== true);
};

const getCurrentIndex = (projects, id) => {
  let currentIndex = null;
  projects.map((project, index) => {
    if (project.node.id === id) currentIndex = index;
    return currentIndex;
  });
  return currentIndex;
};

const getNextProject = (projectEdges, id) => {
  const visibleProjects = getVisibleProjects(projectEdges);
  const currentIndex = getCurrentIndex(visibleProjects, id);

  const nextProject = visibleProjects[currentIndex + 1] || visibleProjects[0];
  return nextProject.node;
};
const getPreviousProject = (projectEdges, id) => {
  const visibleProjects = getVisibleProjects(projectEdges);
  const currentIndex = getCurrentIndex(visibleProjects, id);

  const prevProject =
    visibleProjects[currentIndex - 1] ||
    visibleProjects[visibleProjects.length - 1];
  return prevProject.node;
};

export default ({ pageContext }) => {
  const {
    content,
    id,
    layout,
    seo,
    socials,
    projectEdges,
    cookieConsent,
    _rawProjectInfo,
  } = pageContext;

  // these have to be destructured and backed by hand since it can be that these
  // variables might not've been set in sanity cms by default. also we have to hand them
  // throu as single variables to make sure the site doesnt break in edge cases. ~DH
  const hasHeader = layout?.hasHeader || true;
  const hasFooter = layout?.hasFooter || true;

  return (
    <Layout
      hasHeader={hasHeader}
      hasFooter={hasFooter}
      seo={seo}
      socials={socials}
      cookieConsent={cookieConsent}
    >
      <SectionProjectHero projectInfo={_rawProjectInfo} />
      {content && (
        <Sections
          content={content}
          layout={layout}
          hasHeader={hasHeader}
          hasFooter={hasFooter}
        />
      )}
      <SectionProjectFooter
        next={getNextProject(projectEdges, id)}
        previous={getPreviousProject(projectEdges, id)}
        background={_rawProjectInfo?.footerBackground}
      />
    </Layout>
  );
};
