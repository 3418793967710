import { sx } from '@lucifer/sx';

import LinkComponent from '~/components/Link';

export const LinkText = sx('span')({
  display: 'inline-block',
  transition: 'all 300ms ease-out',
});

export const Link = sx(LinkComponent)({
  background: 'white',
  padding: '53px 57px 53px 50px',
  display: 'block',
  fontSize: 17,
  fontWeight: 600,
  letterSpacing: '0.02em',
  lineHeight: '27px',
  textTransform: 'uppercase',
  transition: 'all 300ms ease-out',
  color: 'black',

  svg: {
    marginRight: 35,
    transition: 'all 500ms ease-out',
  },

  '&:hover': {
    color: 'inherit',

    [LinkText]: {
      transform: 'translateX(-5px)',
    },

    svg: {
      transform: 'rotate(180deg) translateX(10px)',
    },
  },
});
