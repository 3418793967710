import { sx } from '@lucifer/sx';
import styled from '@emotion/styled';

import { media } from '~/utils/styles';
import { Flex, Box } from 'theme-ui';

import Image from '~/components/Image';
import Headline from '~/components/Headline';
import HeadlineSub from '~/components/HeadlineSub';
import Modules from '~/components/Modules';
import Section from '~/components/Section';
import Wrap from '~/components/Wrap';

export const Hero = sx.Box({});

export const HeroHeader = sx.Box({
  position: 'relative',
  height: 'auto',

  [media('laptop')]: {
    height: '85vh',
  },
});

export const HeroHeaderImage = sx(Image)({
  position: 'relative',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  obectFit: 'cover',

  [media('laptop')]: {
    position: 'absolute',
  },
});

export const HeroDetailImage = styled(Image)({
  position: 'relative',
  width: '100%',
  img: {
    objectPosition: 'center left!important',
  },

  [media('laptop')]: {
    transform: 'translateX(8.5vw)',
  },
});

export const HeroIntro = sx(Section)({
  position: 'relative',
  minHeight: 'auto',
  padding: '65px 0 20px',
});

export const HeroIntroWrap = sx(Wrap)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  [media('laptop')]: {
    flexDirection: 'row',
  },
});

export const TopTitle = sx(HeadlineSub)({
  color: '#A59E92',
  marginBottom: '20px',
  [media('tablet')]: {
    marginBottom: '40px',
  },
});

export const ProjectTitle = sx(Headline)({
  color: '#ffffff',
});

export const HeroInfoWrap = sx(Wrap)({
  width: '100%',

  [media('laptop')]: {
    paddingLeft: '0',
    paddingRight: '0',
  },
});

export const HeroInfoFlex = sx(Flex)({
  display: 'flex',
  flexDirection: 'column-reverse',

  [media('laptop')]: {
    flexDirection: 'row',
  },
});

export const HeroInfoWrapMeta = sx(Box)({
  flexBasis: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: '40px',

  [media('laptop')]: {
    flexBasis: '40%',
    maxWidth: '40%',
    paddingRight: '5%',
    paddingLeft: '8.5vw',
    marginTop: '0',
  },
});

export const HeroInfoWrapImage = sx(Box)({
  flexBasis: '100%',
  maxWidth: '100%',
  marginTop: '20px',
  display: 'flex',
  alignItems: 'center',
  [media('laptop')]: {
    flexBasis: '60%',
    maxWidth: '60%',
    marginTop: '0',
  },
});

export const MetaTableItem = sx.Flex({
  padding: '30px 0',
  alignItems: 'center',
});

export const MetaTable = sx.Box({
  position: 'relative',
});

export const MetaTableTitle = sx.Box({
  minWidth: '100px',
  marginRight: '20px',
  fontSize: '12px',
  fontWeight: 'bold',
  color: '#383734',
  textTransform: 'uppercase',
});

export const MetaTableImage = sx(Image)({
  display: 'block',
});

export const MetaTableImageWrap = sx.Box({
  width: '100%',
  maxWidth: '110px',
});

export const MetaTableText = sx(Modules)({
  color: '#6B6760',
  fontSize: '14px',
});

export const List = sx.Box({
  color: '#6B6760',
});

export const ListItem = sx.Box({
  color: '#6B6760',
  fontSize: '14px',
});
